import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import ImageText from "../components/image-text";
import TextImage from "../components/text-image";
import Li from "../components/li";
import UiLink from "../components/ui-link";
import UiHeading from "../components/ui-heading";
import UiLinkContainer from "../components/ui-link-container";

import mainImg from "../images/undraw_faq_rjoy.svg";

import buyIcon from "../images/icons/buy.svg";
import questionIcon from "../images/icons/question.svg";
import downloadIcon from "../images/icons/download.svg";
import crossIcon from "../images/icons/cross.svg";
import shieldIcon from "../images/icons/shield.svg";
import browserIcon from "../images/icons/browser.svg";
import squeegeeIcon from "../images/icons/squeegee.svg";
import windowsIcon from "../images/icons/windows.svg";
import ramIcon from "../images/icons/ram.svg";
import bellIcon from "../images/icons/bell.svg";
import slowIcon from "../images/icons/slow.svg";
import desktopIcon from "../images/icons/desktop.svg";
import mainboardIcon from "../images/icons/mainboard.svg";
import cogIcon from "../images/icons/cog.svg";
import flashIcon from "../images/icons/flash-drive.svg";
import hddIcon from "../images/icons/hard-drive.svg";

function FaqPage() {
    return (
      <Layout>
          <SEO
        title="Často kladené dotazy"
      />
        <Section>
            <MainHeading heading="Často kladené dotazy" section="Rozcestník"/>
            <UiHeading>Doporučení hardwaru</UiHeading>
            <UiLinkContainer>
            <UiLink 
                headerText="Chci si koupit nový počítač. Co doporučíte?"
                descriptionText="Herní, multimediální, kancelářské a pracovní PC"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1297583040437087/"
                image={desktopIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Chci si pořídit nové komponenty do počítače. Co doporučíte?"
                descriptionText="Základní desky, skříně, procesory, pevné disky a další"
                link="https://pcporadna.net/hardware/komponenty/"
                image={mainboardIcon}/>
            </UiLinkContainer>
            
            <UiHeading>Doporučení softwaru</UiHeading>
            <UiLinkContainer>
            <UiLink 
                headerText="Jaké programy mohu použít pro diagnostiku a monitoring?"
                descriptionText="Programy pro testování stability, výkonu a sledování provozních vlastností"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1384522458409811/"
                image={crossIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Jaký je podle vás nejlepší antivir?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1048434618685265/"
                image={shieldIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Jaký je podle vás nejlepší webový prohlížeč?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1400675623461161/"
                image={browserIcon}/>
            </UiLinkContainer>

            <UiHeading>Windows</UiHeading>
            <UiLinkContainer>
            <UiLink 
                headerText="Jak můžu stáhnout Windows?"
                descriptionText="Stažení Windows 10, Windows 8, Windows 7 a starších"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1470298399832216/"
                image={downloadIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Kde můžu koupit licenci Windows levně?"
                descriptionText="Windows 10 levně nebo zdarma"
                link="https://www.pcporadna.net/software/windows-10-levne-nebo-zdarma/"
                image={buyIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Jak mám nainstalovat nebo přeinstalovat Windows?"
                descriptionText="Čistá instalace, lepší alternativa k továrnímu nastavení"
                link="https://www.pcporadna.net/software/instalace-windows/"
                image={windowsIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Jak nejlépe vyčistit počítač?"
                descriptionText="Bezpečné čištění Windows"
                link="https://www.pcporadna.net/software/bezpecne-cisteni-windows/"
                image={squeegeeIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Jakým softwarem mám aktualizovat ovladače?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1395495900645800/"
                image={cogIcon}/>
            </UiLinkContainer>

            <UiHeading>Technické problémy</UiHeading>
            <UiLinkContainer>
            <UiLink 
                headerText="Proč mám pouze poloviční frekvenci RAM?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1371227893072601/"
                image={ramIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Proč Windows nechce využít plnou kapacitu RAM?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1347052645490126/"
                image={ramIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Proč nemůžu přesunout velký soubor na flashku?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1513913345470721/"
                image={flashIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Proč má můj disk menší velikost než je uvedeno na obalu?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1514057348789654/"
                image={hddIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Jak se zbavit otravných notifikací?"
                descriptionText="Okénka s nahotinkama nebo s jinou reklamou"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1347050305490360/"
                image={bellIcon}/>
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
                headerText="Proč stahuji 8x pomaleji než je moje rychlost internetu?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/1297753653753359/"
                image={slowIcon}/>
            </UiLinkContainer>

        <UiHeading>Ostatní</UiHeading>
        <UiLinkContainer>
            <UiLink 
                headerText="Na kolik FPS mi poběží hry?"
                link="https://www.facebook.com/groups/PCporadna.net/permalink/787856424743087/"
                image={questionIcon}/>
            </UiLinkContainer>
        </Section>

      </Layout>

      );
    }
    
export default FaqPage;
